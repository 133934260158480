import React from 'react';
import SettingsMain from '../components/settings';

const SettingsPage = () => {
    return(
        <>
            <SettingsMain />
        </>
    )
}

export default SettingsPage;