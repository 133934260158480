import React, { useState, useEffect } from 'react';
import { DashboardMainFlex, BasicFlex, BalanceCard, Line, GreyText, DashboardMainBody, AppMainBody, DescHeader, InputField, FlexedBtn, UserCard, UserProfileCard, GridTexts, IconCard} from '../../styles/reusable/index';
import SideBarWidget from '../reusable/sidebar';
import Header from '../reusable/header';
import * as Icon from 'iconsax-react';
import * as FeatherIcon from 'react-feather';
import BreadcrumbArea from '../reusable/breadcrumb';
import { AvatarImage } from '../reusable/style';
import axios from 'axios';
import { useNavigate } from 'react-router';
import Alert from '../reusable/alert';
import { BeatLoader, ClipLoader } from 'react-spinners';
import moment from 'moment';
import CommaNumber from 'comma-number';

const DebitAccount = () => {
    
    const navigate = useNavigate();
    const loggedAdmin = localStorage.getItem('tks') || '{}';
    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [activeLookup, setActiveLookup] = useState('1')
    const [id, setId] = useState('')
    const [showIdModal, setShowIdModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [email, setEmail] = useState('')
    const [amount, setAmount] = useState('')
    const [narration, setNarration] = useState('');
    const [userInformation, setUserInformation] = useState<any>({})
    const [onProcess, setOnProcess] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState('')

    const body = {
        headers: { 
            "Content-Type": "application/json",
            "x-token": `${loggedAdmin}`
        },
    }

    const getUserByEmail = () => {
        if (email.length > 0) {
          setFetching(true);
          axios
            .get(
              `${process.env.REACT_APP_BASE_URL}/admin/search/users?limit=20&key=${email}`,
              body
            )
            .then((res) => {
              setFetching(false);
              setFetched(true);
              if (res.data.data.length > 0) {
                  setUserInformation(res.data.data[0]);
              }
            })
            .catch((err) => {
              setFetching(false);
              setError(err.response.data.message);
              if (err.response.status === 403) {
                localStorage.clear();
                navigate("/login");
              }
            });
        }
      };
    

    const payload = {
        amount,
        username: userInformation.walletTag
    }

    const fundAction = (e:any) => {
        e.preventDefault();
        setOnProcess(true)
        axios.post( `${process.env.REACT_APP_BASE_URL}/admin/panel/account/debit`, payload, {
            headers: {
                "x-token": `${loggedAdmin}`
            }
        }).then((res) => {
            setOnProcess(false);
            setSuccess(true);
        }).catch((err) => {
            setOnProcess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }

    return(
        <>
            {
                success ? 
                    <Alert
                        closeFunc={() => setSuccess(false)}
                        img='/icons/success.png'
                        message='Debit successful!!!'
                        miniMessage={`You have successfully debited ${userInformation ? userInformation.firstName + ' ' + userInformation.surame : ''} the sum of ₦${amount}`}
                    />
                :null
            }
            {
                 error ? 
                    <Alert
                        closeFunc={() => setError('')}
                        img='/icons/error.png'
                        message='An error occured!'
                        miniMessage={error}
                    />
                    :null
            }
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <form onSubmit={(e) => fundAction(e)}>
                        <BreadcrumbArea 
                            navName='Debit Account'
                            redirectUrl='/account'
                        />
                        <DescHeader>
                            <h3>Debit Account</h3>
                        </DescHeader>
                        <InputField
                            style={{
                                width: '15rem'
                            }}
                        >
                            <legend>Email</legend>
                            <input 
                                type='text'
                                placeholder='Enter Email'
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={() => getUserByEmail()}
                                required
                            />
                        </InputField>
                        <InputField
                            style={{
                                width: '15rem'
                            }}
                        >
                            <legend>Amount</legend>
                            <input 
                                type='number'
                                placeholder='Amount'
                                onChange={(e) => setAmount(e.target.value)}
                                required
                            />
                        </InputField>
                        <InputField
                            style={{
                                width: '15rem'
                            }}
                        >
                            <legend>Purpose</legend>
                            <input 
                                type='text'
                                placeholder='Narration'
                                required
                            />
                        </InputField>
                        {
                            fetched && userInformation && Object.keys(userInformation).length > 0 ?
                                <UserCard
                                    style={{
                                        margin: "3rem 0 0 0",
                                    }}
                                    >
                                    <div>
                                        <div>
                                        <p>User Id</p>
                                        <h3>{userInformation.uid}</h3>
                                        </div>
                                        <div>
                                        <BasicFlex>
                                            <IconCard background="#E0E3EB" color="#122466">
                                            <Icon.Call />
                                            </IconCard>
                                            <div>
                                            <GreyText>PHONE</GreyText>
                                            <h3>{userInformation.phone}</h3>
                                            </div>
                                        </BasicFlex>
                                        </div>
                                    </div>
                                    <Line />
                                    <div>
                                        <UserProfileCard>
                                        {userInformation.image ? (
                                            <AvatarImage src={userInformation.image} alt="Profile" />
                                        ) : (
                                            <IconCard
                                            background="#FEF9E5"
                                            color="#FFAB01"
                                            style={{
                                                fontWeight: 800,
                                            }}
                                            >
                                            {userInformation.firstName ? userInformation.firstName[0].toUpperCase() : ""}
                                            </IconCard>
                                        )}
                                        <div>
                                            <span>User</span>
                                            <h3>{`${userInformation.firstName} ${userInformation.surname}`}</h3>
                                            <p>{userInformation.email}</p>
                                        </div>
                                        </UserProfileCard>
                                        <GridTexts>
                                        <p>Created</p>
                                        <h3>{moment(userInformation.createdAt).format("LL")}</h3>
                                        </GridTexts>
                                        <GridTexts>
                                        <p>Updated</p>
                                        <h3>{moment(userInformation.updatedAt).format("LL")}</h3>
                                        </GridTexts>
                                        <BalanceCard>
                                        <IconCard background="#4DC736" color="#fff">
                                            &#8358;
                                        </IconCard>
                                        <GridTexts>
                                            <p>Balance</p>
                                            <h3>&#8358;{CommaNumber(userInformation ? userInformation.walletBalance : "")}</h3>
                                        </GridTexts>
                                        </BalanceCard>
                                    </div>
                                </UserCard>

                            : null
                        }
                                {
                                    fetched && userInformation && Object.keys(userInformation).length > 0 ?
                                        <FlexedBtn
                                            style={{
                                                margin: '2rem 0 0 0',
                                                gap: '5px'
                                            }}
                                        >
                                            <button
                                                type='submit'
                                                disabled={onProcess}
                                                style={
                                                    onProcess ? {
                                                        cursor: 'not-allowed'
                                                    } :{}
                                                }
                                            >
                                                {onProcess ? <ClipLoader /> : 'Debit Account'}
                                            </button>
                                        </FlexedBtn>
                                        : null
                                }
                    </form>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
    )
}

export default DebitAccount;

const lookupArray = [
    {
        name: 'BVN',
    },
    {
        name: 'Identity'
    }
]