import React, { useState, useEffect } from 'react';
import { BigCardsGrid, GraphCard, TopRight } from '../../../styles/dashboard/graphStyle';
import { InputField, GridPaddedSpace, FlexedBtn } from '../../../styles/reusable/index';
import axios from 'axios';
import { Button } from '../../../styles/reusable';
import { Link } from 'react-router-dom';
import Alert from '../../reusable/alert';
import { ClipLoader } from 'react-spinners';

interface PropsArgs {
    mode: string;
    active: string;
    setActive: any;
    setMode: any;
    model: any;
    activeVendor: string;
    vendors: Array<[]>
}

const Disbursement = ({mode, active, setActive, setMode, model, vendors, activeVendor} : PropsArgs) => {

    const loggedAdmin = localStorage.getItem('tks') || '{}';

    // States
    const [vendorName, setVendorName] = useState('')
    const [max5, setMax5] = useState('')
    const [max50, setMax50] = useState('')
    const [min50, setMin50] = useState('')
    const [mongoroMax1k, setMongoroMax1k] = useState('')
    const [mongoroMax3k, setMongoroMax3k] = useState('')
    const [mongoroMax5, setMongoroMax5] = useState('')
    const [mongoroMax10k, setMongoroMax10k] = useState('')
    const [mongoroMax20k, setMongoroMax20k] = useState('')
    const [mongoroMax50, setMongoroMax50] = useState('')
    const [mongoroMax100, setMongoroMax100] = useState('')
    const [mongoroMin100, setMongoroMin100] = useState('')
    const [onProcess, setOnProcess] = useState(false);
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)

    const basicPayload = {
        vendorName,
        fee0to5000: max5,
        fee5000to50000: max50,
        fee50000: min50,
        mongoroFee100to1000: mongoroMax1k,
        mongoroFee1000to3000: mongoroMax3k,
        mongoroFee3000to5000: mongoroMax5,
        mongoroFee5000to10000: mongoroMax10k,
        mongoroFee10000to20000: mongoroMax20k,
        mongoroFee20000to50000: mongoroMax50,
        mongoroFee50000to100000: mongoroMax100,
        mongoroFee100000: mongoroMin100
    }

    useEffect(() => {
        setVendorName(activeVendor)
    }, [activeVendor])

    useEffect(() => {
        if (model && Object.keys(model).length > 0){
            setVendorName(model.vendorName)
            setMax5(model.fee0to5000)
            setMax50(model.fee5000to50000)
            setMin50(model.fee50000)
            setMongoroMax1k(model.mongoroFee100to1000)
            setMongoroMax3k(model.mongoroFee1000to3000)
            setMongoroMax5(model.mongoroFee3000to5000)
            setMongoroMax10k(model.mongoroFee5000to10000)
            setMongoroMax20k(model.mongoroFee10000to20000)
            setMongoroMax50(model.mongoroFee20000to50000)
            setMongoroMax100(model.mongoroFee50000to100000)
            setMongoroMin100(model.mongoroFee100000)
        }
    }, [model])

    const editModel = () => {
        setOnProcess(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}/super/admin/business/disbursement`, basicPayload, {
            headers: {
                "x-token": `${loggedAdmin}`
            }
        }).then((res) => {
            setSuccess(true);
            setOnProcess(false);
        }).catch((err) => {
            setOnProcess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }
    
    return(
        <>
            <GraphCard margin='0 0 2rem 0'>
                <TopRight>
                    <Button
                        bg='#EFF3BE'
                        color='#ffab01'
                        onClick={() => {
                                mode === 'edit' && active === 'disbursement' ? 
                                setMode('view') 
                                    : 
                                    setMode('edit');
                                    setActive('disbursement')
                                
                                }
                            }
                    >
                        {mode === 'edit' && active === 'disbursement' ? 'View' : 'Edit'}
                    </Button>
                </TopRight>
                <BigCardsGrid>
                    <GridPaddedSpace>
                        <h3>Disbursement</h3>
                        <div>
                            <InputField>
                                <legend>Vendor name</legend>
                                <select
                                    onChange={(e) => setVendorName(e.target.value)}
                                    value={vendorName}
                                >
                                    {
                                        vendors && vendors.length > 0 ?
                                            vendors.map((item:any, index) => (
                                                <option key={index}>
                                                    {item.vendorName}
                                                </option>
                                            ))
                                            : 
                                            <option>No Vendor</option>
                                    }
                                </select>
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (N0 - 5,000)</legend>
                                <input 
                                    placeholder={'0.00'}
                                    onChange={(e) => setMax5(e.target.value)}
                                    type='number'
                                    value={max5}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (N5,000 - N50,000)</legend>
                                <input 
                                    placeholder={'0.000'}
                                    onChange={(e) => setMax50(e.target.value)}
                                    type='number'
                                    value={max50}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (&gt;N50,000)</legend>
                                <input 
                                    placeholder={'0.000'}
                                    onChange={(e) => setMin50(e.target.value)}
                                    type='number'
                                    value={min50}
                                />
                            </InputField>
                        </div>
                    </GridPaddedSpace>
                    <GridPaddedSpace>
                        <div>
                        <h3>Mongoro</h3>
                        <div>
                            <InputField>
                                <legend>Fee (N100 - 1,000)</legend>
                                <input 
                                    placeholder={'0.00'}
                                    onChange={(e) => setMongoroMax1k(e.target.value)}
                                    type='number'
                                    value={mongoroMax1k}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (N1,000 - 3,000)</legend>
                                <input 
                                    placeholder={'0.00'}
                                    onChange={(e) => setMongoroMax3k(e.target.value)}
                                    type='number'
                                    value={mongoroMax3k}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (N3,000 - 5,000)</legend>
                                <input 
                                    placeholder={'0.00'}
                                    onChange={(e) => setMongoroMax5(e.target.value)}
                                    type='number'
                                    value={mongoroMax5}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (N5,000 - 10,000)</legend>
                                <input 
                                    placeholder={'0.00'}
                                    onChange={(e) => setMongoroMax10k(e.target.value)}
                                    type='number'
                                    value={mongoroMax10k}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (N10000 - 20,000)</legend>
                                <input 
                                    placeholder={'0.00'}
                                    onChange={(e) => setMongoroMax20k(e.target.value)}
                                    type='number'
                                    value={mongoroMax20k}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (N20,000 - N50,000)</legend>
                                <input 
                                    placeholder={'0.000'}
                                    onChange={(e) => setMongoroMax50(e.target.value)}
                                    type='number'
                                    value={mongoroMax50}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (N50,000 - 100,000)</legend>
                                <input 
                                    placeholder={'0.00'}
                                    onChange={(e) => setMongoroMax100(e.target.value)}
                                    type='number'
                                    value={mongoroMax100}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (&gt;N100,000)</legend>
                                <input 
                                    placeholder={'0.000'}
                                    onChange={(e) => setMongoroMin100(e.target.value)}
                                    type='number'
                                    value={mongoroMin100}
                                />
                            </InputField>
                        </div>
                    </div>
                    </GridPaddedSpace>
                </BigCardsGrid>
                {mode === 'edit' && active === 'disbursement' ? 
                    <FlexedBtn
                    style={{
                        margin: '0rem 0 1rem 0',
                        gap: '5px'
                    }}
                >
                    <button
                        style={{cursor: 'pointer' }}
                        onClick={() => editModel()}
                    >
                        {onProcess ? <ClipLoader color='#fff' /> : 'Save Changes'}
                    </button>
                </FlexedBtn>
                :
                <FlexedBtn
                    style={{
                        margin: '0rem 0 1rem 0',
                        gap: '5px'
                    }}
                >
                    <Link to={`/discounted-users/disbursement`}>
                        <button
                            style={{
                                border: '1px solid #ffab01',
                                color: '#ffab01',
                                background: 'transparent'
                            }}
                        >
                            View discounted users
                        </button>
                    </Link>
                </FlexedBtn>
                }
            </GraphCard>
            {
                success ? 
                    <Alert
                        closeFunc={() => setSuccess(false)}
                        img='/icons/success.png'
                        message='Modified Successfully'
                        miniMessage="You have successfully modified disbursement model"
                    />
                :null
            }
            {
                 error ? 
                    <Alert
                        closeFunc={() => setError('')}
                        img='/icons/error.png'
                        message='An error occured!'
                        miniMessage={error}
                    />
                    :null
            }
        </>
    )
}

export default Disbursement;