import React, { useState, useEffect } from 'react';
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, InputField, FlexedBtn, UserCard, UserProfileCard, GridTexts, IconCard} from '../../styles/reusable/index';
import SideBarWidget from '../reusable/sidebar';
import Header from '../reusable/header';
import BreadcrumbArea from '../reusable/breadcrumb';
import axios from 'axios';
import { useNavigate } from 'react-router';
import CreateProvider from './createProvider';
import Alert from '../reusable/alert';
import { ClipLoader } from 'react-spinners';

const Providers = () => {
    
    const navigate = useNavigate();
    const loggedAdmin = localStorage.getItem('tks') || '{}';
    const [fetched, setFetched] = useState(false);
    const [providers, setProviders] = useState<any>([]);
    const [active, setActive] = useState('');
    const [activeIndex, setActiveIndex] = useState<string | number>(1)
    const [id, setId] = useState('')
    const [showIdModal, setShowIdModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [userInformation, setUserInformation] = useState<any>({})
    const [onProcess, setOnProcess] = useState(false);
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)

    const body = {
        headers: { 
            "Content-Type": "application/json",
            "x-token": `${loggedAdmin}`
        }
    }

    // Call Function 
    const getBlockedUsers = () => {
        const loggedAdmin = localStorage.getItem('tks') || '{}';

        const body = {
            headers: { 
                "Content-Type": "application/json",
                "x-token": `${loggedAdmin}`
            },
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/super/admin/business/service/providers/`, body)
            .then(res => {
                setProviders(res.data.data);
                if (res.data.data.length > 0){
                    for (let i = 0; i < res.data.data.length; i++){
                        if (res.data.data[i].active){
                            setActive(res.data.data[i].vendorName);
                        }
                    }
                }
            }).catch(err => {
                if(err.response.status === 403){
                    localStorage.clear();
                    navigate('/login');
                }
            })
    }

    useEffect(() => {
        if (providers && providers.length > 0){
            setId(providers.find((p:any) => p.vendorName === active).prid)
        }
    }, [active])

    console.log(id)

    const setActiveProvider= () => {
        setOnProcess(true)
        axios.put(`${process.env.REACT_APP_BASE_URL}/super/admin/business/providers/active`, {prid: id}, {
            headers: {
                "x-token": `${loggedAdmin}`
            }
        }).then((res) => {
            setSuccess(true);
            setOnProcess(false);
        }).catch((err) => {
            setOnProcess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }

    console.log(active)
    // Get Transactions
    useEffect(() => {
        getBlockedUsers();
    }, [])

    return(
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea 
                            navName='Service Providers'
                            redirectUrl='/account'
                        />
                        <DescHeader>
                            <h3>Services Providers</h3>
                        </DescHeader>
                        <InputField
                            style={{
                                width: '15rem'
                            }}
                        >
                            <legend>Select Provider</legend>
                            <select
                                required
                                placeholder={'Select option'}
                                value={active}
                                onChange={(e) => {
                                    setFetched(false)
                                    setActive(e.target.value);
                                }}
                            >
                                {
                                    providers.length > 0 ? 
                                        providers.map((item:any, index:number) => (
                                            <option key={index}>{item.vendorName}</option>
                                    ))

                                    : null
                                }
                            </select>
                        </InputField>
                        <FlexedBtn
                            style={{
                                margin: '2rem 0 0 0',
                                gap: '5px'
                            }}
                        >
                            <button
                                onClick={() => setActiveProvider()}
                            >
                                {onProcess ? <ClipLoader color='#fff' /> : 'Save'}
                            </button>
                            <button
                                onClick={() => setShowIdModal(true)}
                                style={{
                                    color: '#ffab01',
                                    background: 'transparent',
                                    border: '1px solid #ffab01'
                                }}
                                
                            >
                                Create Provider
                            </button>
                        </FlexedBtn>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
            {
                showIdModal ? 
                    <CreateProvider 
                        closeFunc={() => setShowIdModal(false)}
                    />
                    : null
            }
            {
                success ? 
                    <Alert
                        closeFunc={() => setSuccess(false)}
                        img='/icons/success.png'
                        message='Modified Successfully'
                        miniMessage="You have successfully modified the active provider"
                    />
                :null
            }
            {
                 error ? 
                    <Alert
                        closeFunc={() => setError('')}
                        img='/icons/error.png'
                        message='An error occured!'
                        miniMessage={error}
                    />
                    :null
            }
        </>
    )
}

export default Providers;

const lookupArray = [
    {
        name: 'Block Transfer',
    },
    {
        name: 'Block Account'
    },
    {
        name: 'Block P2P Transfer'
    }
]