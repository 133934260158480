import React, { useState, useEffect } from "react";
import { ModalWrap, ModalChild, ModalHeader, DashboardInput, UserFlex, IconCard } from "../../styles/reusable/index";
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather';
import axios from 'axios';
import EmptyState from '../reusable/emptyState';
import Loader from '../reusable/loader';
import { useNavigate } from 'react-router'

interface PropArgs {
    closeFunc: any;
    category: string;
}

const UsersList = ({closeFunc, category} : PropArgs) => {

    const navigate = useNavigate();
    const [users, setUsers] = useState<any>('');
    const [usersMutable, setMutableUsers] = useState([]);

    // Get Users
    useEffect(() => {
        const loggedAdmin = localStorage.getItem('tks') || '{}';

        const body = {
            headers: { 
                "Content-Type": "application/json",
                "x-token": `${loggedAdmin}`
            },
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/super/admin/category/${category}`, body)
            .then(res => {
                setUsers(res.data.data);
                setMutableUsers(res.data.data);
            }).catch(err => {
                if(err.response.status === 403){
                    localStorage.clear();
                    navigate('/login');
                }
            })
    }, [])

// Search Algorithm
const handleSearch: any = (e:any) => {
    let new_item: any = [];
    if (users !== '' && users.length !== 0) {
      for (let i = 0; i < users.length; i++) {
        if (
            users[i].name && users[i].name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||

            users[i].email && users[i].email
            .toLowerCase()
            .includes(e.target.value.toLowerCase())

        ) {
          new_item.push(users[i]);
        } else if (e.target.value === '') {
          setMutableUsers(users);
        }
      }
      setMutableUsers(new_item);
    }
  };

    return(
        <>
            <ModalWrap>
                <ModalChild>
                    <ModalHeader>
                        <h3>{category}</h3>
                        <i><FeatherIcon.X onClick={() => closeFunc()} /></i>
                    </ModalHeader>
                    <DashboardInput showSearch={true}>
                        <input 
                            placeholder='Search User name here'
                            onChange={(e) => handleSearch(e)}
                        />
                        <i><Icon.SearchNormal1 size={18} /></i>
                    </DashboardInput>
                    {
                        users !== '' ?
                        usersMutable && usersMutable.length > 0 ?
                            <>
                                {
                                    usersMutable.map((item:any, index:number) => (
                                        <UserFlex>
                                            {/* <input 
                                                type='checkbox'
                                            /> */}
                                            <div>
                                                <IconCard
                                                    background='#FEF9E5'
                                                    color='#FFAB01'
                                                    style={{
                                                        fontWeight: 800
                                                    }}
                                                >
                                                    {item.email && item.email[0].toUpperCase()}
                                                </IconCard>
                                                <div>
                                                    <h3>{item.email}</h3>
                                                    <p>ID - {item.aid}</p>
                                                </div>
                                            </div>
                                            {/* <FeatherIcon.MoreVertical
                                                size={18}
                                            /> */}
                                        </UserFlex>
                                        ))
                                    }
                                </>
                            : 
                                <EmptyState 
                                    imgSize={'3rem'}
                                />
                            : 
                            <Loader />
                        }
                </ModalChild>
            </ModalWrap>
        </>
    )
}

export default UsersList;