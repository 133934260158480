import React, { useEffect, useState } from 'react';
import { BigCardsGrid, GraphCard, TopRight } from '../../../styles/dashboard/graphStyle';
import { InputField, GridPaddedSpace, FlexedBtn } from '../../../styles/reusable/index';
import axios from 'axios';
import { Button } from '../../../styles/reusable';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Alert from '../../reusable/alert';

interface PropsArgs {
    mode: string;
    active: string;
    setActive: any;
    setMode: any;
    model: any;
    activeVendor: string;
    vendors: Array<[]>;
}

const Gateway = ({mode, active, setActive, setMode, model, vendors, activeVendor} : PropsArgs) => {

    const loggedAdmin = localStorage.getItem('tks') || '{}';

    // States
    const [vendorName, setVendorName] = useState('')
    const [genericFee, setGenericFee] = useState('')
    const [genericCap, setGenericCap] = useState('')
    const [mongoroCap, setMongoroCap] = useState('')
    const [mongoroFee, setMongoroFee] = useState('')
    const [onProcess, setOnProcess] = useState(false);
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)

    const basicPayload = {
        vendorName,
        feePercent: genericFee,
        cap: genericCap,
        mongoroFeePercent: mongoroFee,
        mongoroCap
    }

    useEffect(() => {
        setVendorName(activeVendor)
    }, [activeVendor])

    useEffect(() => {
        if (model && Object.keys(model).length > 0){
            setVendorName(model.vendorName)
            setGenericFee(model.feePercent)
            setGenericCap(model.cap)
            setMongoroFee(model.mongoroFeePercent)
            setMongoroCap(model.mongoroCap)
        }
    }, [model])

    const editModel = () => {
        setOnProcess(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}/super/admin/business/gateway`, basicPayload, {
            headers: {
                "x-token": `${loggedAdmin}`
            }
        }).then((res) => {
            setSuccess(true);
            setOnProcess(false);
        }).catch((err) => {
            setOnProcess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }

    return(
        <>
            <GraphCard margin='0 0 2rem 0'>
                <TopRight>
                    <Button
                        bg='#EFF3BE'
                        color='#ffab01'
                        onClick={() => {
                                mode === 'edit' && active === 'gateway' ? 
                                setMode('view') 
                                    : 
                                    setMode('edit');
                                    setActive('gateway')
                                
                                }
                            }
                    >
                        {mode === 'edit' && active === 'gateway' ? 'View' : 'Edit'}
                    </Button>
                </TopRight>
                <BigCardsGrid>
                    <GridPaddedSpace>
                        <h3>Gateway Pay-In</h3>
                        <div>
                            <InputField>
                                <legend>Vendor name</legend>
                                <select
                                    onChange={(e) => setVendorName(e.target.value)}
                                    value={vendorName}
                                >
                                    {
                                        vendors && vendors.length > 0 ?
                                            vendors.map((item:any, index) => (
                                                <option key={index}>
                                                    {item.vendorName}
                                                </option>
                                            ))
                                            : 
                                            <option>No Vendor</option>
                                    }
                                </select>
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Fee (in %)</legend>
                                <input 
                                    placeholder={'0.00'}
                                    onChange={(e) => setGenericFee(e.target.value)}
                                    type='number'
                                    value={genericFee}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Cap</legend>
                                <input 
                                    placeholder={'0.000'}
                                    onChange={(e) => setGenericCap(e.target.value)}
                                    type='number'
                                    value={genericCap}
                                />
                            </InputField>
                        </div>
                    </GridPaddedSpace>
                    <GridPaddedSpace>
                        <div>
                        <h3>Mongoro</h3>
                        <div>
                            <InputField>
                                <legend>Fee (in %)</legend>
                                <input 
                                    placeholder={'0.00'}
                                    onChange={(e) => setMongoroFee(e.target.value)}
                                    type='number'
                                    value={mongoroFee}
                                />
                            </InputField>
                        </div>
                        <div>
                            <InputField>
                                <legend>Cap</legend>
                                <input 
                                    placeholder={'0.000'}
                                    onChange={(e) => setMongoroCap(e.target.value)}
                                    type='number'
                                    value={mongoroCap}
                                />
                            </InputField>
                        </div>
                    </div>
                    </GridPaddedSpace>
                </BigCardsGrid>
                {mode === 'edit' && active === 'gateway' ? 
                    <FlexedBtn
                    style={{
                        margin: '0rem 0 1rem 0',
                        gap: '5px'
                    }}
                >
                    <button
                        style={{cursor: 'pointer' }}
                        onClick={() => editModel()}
                    >
                            {onProcess ? <ClipLoader color='#fff' /> : 'Save Changes'}
                    </button>
                </FlexedBtn>
                :
                <FlexedBtn
                    style={{
                        margin: '0rem 0 1rem 0',
                        gap: '5px'
                    }}
                >
                    <Link to={`/discounted-users/gateway`}>
                        <button
                            style={{
                                border: '1px solid #ffab01',
                                color: '#ffab01',
                                background: 'transparent'
                            }}
                        >
                            View discounted users
                        </button>
                    </Link>
                </FlexedBtn>
                }
            </GraphCard>
            {
                success ? 
                    <Alert
                        closeFunc={() => setSuccess(false)}
                        img='/icons/success.png'
                        message='Modified Successfully'
                        miniMessage="You have successfully modified gateway model"
                    />
                :null
            }
            {
                 error ? 
                    <Alert
                        closeFunc={() => setError('')}
                        img='/icons/error.png'
                        message='An error occured!'
                        miniMessage={error}
                    />
                    :null
            }
        </>
    )
}

export default Gateway;