import React from "react";
import { ModalWrap, ModalChild, ModalHeader, InputField } from "../../styles/reusable/index";
import * as FeatherIcon from 'react-feather';
import { Button } from "../../styles/reusable";
import { MiniBtn } from '../../styles/chat';
import * as Icon from 'iconsax-react';

interface PropArgs {
    closeFunc: any;
}

const CreateBroadcast = ({closeFunc} : PropArgs) => {
    return(
        <>
            <ModalWrap>
                <ModalChild>
                    <ModalHeader>
                        <h3>Send Broadcast</h3>
                        <i><FeatherIcon.X onClick={() => closeFunc()} /></i>
                    </ModalHeader>
                   
                    <InputField>
                        <legend>Message Type</legend>
                        <select>
                            <option>Select Notification Type</option>
                            <option>In-App Messaging</option>
                            <option>Widget Notification</option>
                        </select>
                    </InputField>
                    <InputField>
                        <legend>Recipients</legend>
                        <select>
                            <option>Daniel Adewale</option>
                        </select>
                    </InputField>
                    <InputField>
                        <legend>Subject</legend>
                        <input 
                            placeholder={'Enter Subject'}
                        />
                    </InputField>
                    <InputField>
                        <legend>Message</legend>
                        <textarea
                            placeholder='Message'
                        ></textarea>
                    </InputField>
                    <MiniBtn>
                        <Icon.Microphone />
                        Attach File
                    </MiniBtn>
                    <Button
                       width='100%'
                        bg='var(--primary-color)'
                        color='#fff'
                    >
                        Send
                    </Button>
                </ModalChild>
            </ModalWrap>
        </>
    )
}

export default CreateBroadcast;