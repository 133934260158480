import React, { useState, useEffect } from 'react';
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, InputField, FlexedBtn, UserCard, UserProfileCard, GridTexts, IconCard} from '../../styles/reusable/index';
import SideBarWidget from '../reusable/sidebar';
import Header from '../reusable/header';
import * as Icon from 'iconsax-react';
import * as FeatherIcon from 'react-feather';
import BreadcrumbArea from '../reusable/breadcrumb';
import { AvatarImage } from '../reusable/style';
import axios from 'axios';
import { useNavigate } from 'react-router';
import moment from 'moment';
import BlockModal from './enterEmailForBlocking';

const Blockings = () => {
    
    const navigate = useNavigate();
    const loggedAdmin = localStorage.getItem('tks') || '{}';
    const [fetched, setFetched] = useState(false);
    const [activeIndex, setActiveIndex] = useState<string | number>(1)
    const [id, setId] = useState('')
    const [showIdModal, setShowIdModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [userInformation, setUserInformation] = useState<any>({})

    return(
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea 
                            navName='Blocking Activities'
                            redirectUrl='/account'
                        />
                        <DescHeader>
                            <h3>Blocking Activities</h3>
                        </DescHeader>
                        <InputField
                            style={{
                                width: '15rem'
                            }}
                        >
                            <legend>Select Action</legend>
                            <select
                                required
                                placeholder={'Select Action'}
                                onChange={(e) => {
                                    setActiveIndex(e.target.value);
                                    setFetched(false)
                                    setUserInformation({})
                                }}
                            >
                                {
                                    lookupArray.map((item, index) => (
                                        <option key={index} value={index + 1}>{item.name}</option>
                                    ))
                                }
                            </select>
                        </InputField>
                        <FlexedBtn
                            style={{
                                margin: '2rem 0 0 0',
                                gap: '5px'
                            }}
                        >
                            <button
                                onClick={() => setShowIdModal(true)}
                            >
                                Continue
                            </button>
                            <button
                                onClick={() => navigate(`/blocked-users/${activeIndex}`)}
                                style={{
                                    color: '#ffab01',
                                    background: 'transparent',
                                    border: '1px solid #ffab01'
                                }}
                                
                            >
                                View Blocked Users
                            </button>
                        </FlexedBtn>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
            {
                showIdModal ? 
                    <BlockModal 
                        closeFunc={() => setShowIdModal(false)}
                        index={activeIndex}
                    />
                    : null
            }
        </>
    )
}

export default Blockings;

const lookupArray = [
    {
        name: 'Block Transfer',
    },
    {
        name: 'Block Account'
    },
    {
        name: 'Block P2P Transfer'
    }
]