import React from 'react';
import { EmptyImage, EmptyMainWrap } from '../../styles/reusable/emptyState';

interface PropsArgs {
    noShowText?: boolean;
    imgSize?: string;
}

const EmptyState = ({noShowText, imgSize}: PropsArgs) => {
    return(
        <>
            <EmptyMainWrap>
                <div>
                    {
                        !noShowText ?
                            <p>No Record Found</p>
                        : null
                    }
                    
                    <EmptyImage 
                        src='/icons/box.png'
                        alt='Empty Icon'
                        width={imgSize}
                    />
                </div>
            </EmptyMainWrap>
        </>
    )
}

export default EmptyState;