import React, { useState, useEffect } from "react";
import {
  DashboardMainFlex,
  DashboardMainBody,
  AppMainBody,
  DescHeader,
  InputField,
  FlexedBtn,
  UserCard,
  UserProfileCard,
  GridTexts,
  IconCard,
  BasicFlex,
  GreyText,
  BalanceCard,
  Line,
} from "../../styles/reusable/index";
import SideBarWidget from "../reusable/sidebar";
import Header from "../reusable/header";
import * as Icon from "iconsax-react";
import * as FeatherIcon from "react-feather";
import BreadcrumbArea from "../reusable/breadcrumb";
import { AvatarImage } from "../reusable/style";
import axios from "axios";
import { useNavigate } from "react-router";
import Alert from "../reusable/alert";
import { BeatLoader, ClipLoader } from "react-spinners";
import moment from "moment";
import CommaNumber from "comma-number";

const TransferUpgrade = () => {
  const navigate = useNavigate();
  const loggedAdmin = localStorage.getItem("tks") || "{}";
  const [fetching, setFetching] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [checkPoint, setCheckPoint] = useState("");
  const [account, setAccount] = useState<any>({});
  const [newAccount, setNewAccount] = useState({})
  const [userInformation, setUserInformation] = useState<any>({});
  const [newInformation, setNewInformation] = useState<any>({});
  const [onProcess, setOnProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const body = {
    headers: {
      "Content-Type": "application/json",
      "x-token": `${loggedAdmin}`,
    },
  };

  const getUserByEmail = () => {
    if (oldEmail.length > 0) {
      setFetching(true);
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/search/users?limit=20&key=${
            checkPoint === "first" ? oldEmail : newEmail
          }`,
          body
        )
        .then((res) => {
          setFetching(false);
          setFetched(true);
          if (res.data.data.length > 0) {
            if (checkPoint === "first") {
              setUserInformation(res.data.data[0]);
              setAccount(res.data.data[0].account)
            } else if (checkPoint === "second") {
              setNewInformation(res.data.data[0]);
              setNewAccount(res.data.data[0].account)
            }
          }
        })
        .catch((err) => {
          setFetching(false);
          setError(err.response.data.message);
          if (err.response.status === 403) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  };

  const payload = {
    amount,
    oldAccount: oldEmail,
    newAccount: newEmail
  };

  const transferAction = (e: any) => {
    e.preventDefault();
    setOnProcess(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/panel/transferUpgrade`,
        payload,
        {
          headers: {
            "x-token": `${loggedAdmin}`,
          },
        }
      )
      .then((res) => {
        setOnProcess(false);
        setSuccess(true);
      })
      .catch((err) => {
        setOnProcess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError("");
        }, 4000);
      });
  };

  return (
    <>
      {success ? (
        <Alert
          closeFunc={() => setSuccess(false)}
          img="/icons/success.png"
          message="Transfer upgrade successful!!!"
          miniMessage={` ₦${userInformation.walletBalance} sent to ${newInformation ? newInformation.firstName + ' ' + newInformation.surname: ""}`}
        />
      ) : null}
      {error ? (
        <Alert
          closeFunc={() => setError("")}
          img="/icons/error.png"
          message="An error occured!"
          miniMessage={error}
        />
      ) : null}
      <DashboardMainFlex>
        <SideBarWidget mobileDisplay="none" />
        <DashboardMainBody>
          <Header />
          <AppMainBody>
            <form onSubmit={(e) => transferAction(e)}>
              <BreadcrumbArea navName="Transfer Upgrade" redirectUrl="/account" />
              <DescHeader>
                <h3>Transfer Upgrade</h3>
              </DescHeader>
              <InputField
                style={{
                  width: "15rem",
                }}
              >
                <legend>Old Account</legend>
                <input
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => setOldEmail(e.target.value)}
                  onBlur={() => getUserByEmail()}
                  onFocus={() => setCheckPoint("first")}
                  required
                />
              </InputField>
              <InputField
                style={{
                  width: "15rem",
                }}
              >
                <legend>New Account</legend>
                <input
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => setNewEmail(e.target.value)}
                  onBlur={() => getUserByEmail()}
                  onFocus={() => setCheckPoint("second")}
                  required
                />
              </InputField>
              {fetched &&
              userInformation &&
              Object.keys(userInformation).length > 0 ? (
                <Card prop={userInformation} account={account} type={'Old'} />
              ) : null}
              {
              fetched &&
                newInformation &&
                    Object.keys(newInformation).length > 0 ? (
                        <Card prop={newInformation} account={account} type={'New'} />
              ) : null}
              {fetched &&
              userInformation &&
              Object.keys(userInformation).length > 0 ? (
                <FlexedBtn
                  style={{
                    margin: "2rem 0 0 0",
                    gap: "5px",
                  }}
                >
                  <button
                    type="submit"
                    disabled={onProcess}
                    style={
                      onProcess
                        ? {
                            cursor: "not-allowed",
                          }
                        : {}
                    }
                  >
                    {onProcess ? <ClipLoader /> : 'Transfer'}
                  </button>
                </FlexedBtn>
              ) : null}
            </form>
          </AppMainBody>
        </DashboardMainBody>
      </DashboardMainFlex>
    </>
  );
};

export default TransferUpgrade;

const Card = ({ prop, account, type }: any) => {
  return (
    <UserCard
      style={{
        margin: "3rem 0 0 0",
      }}
    >
        
    <h3
        style={{
            color: '#757575'
        }}
    >{type} Account</h3>
      <div>
        <div>
          <p>User Id</p>
          <h3>{prop.uid}</h3>
        </div>
        <div>
          <BasicFlex>
            <IconCard background="#E0E3EB" color="#122466">
              <Icon.Call />
            </IconCard>
            <div>
              <GreyText>PHONE</GreyText>
              <h3>{prop.phone}</h3>
            </div>
          </BasicFlex>
        </div>
      </div>
      <Line />
      <div>
        <UserProfileCard>
          {prop.image ? (
            <AvatarImage src={prop.image} alt="Profile" />
          ) : (
            <IconCard
              background="#FEF9E5"
              color="#FFAB01"
              style={{
                fontWeight: 800,
              }}
            >
              {prop.firstName ? prop.firstName[0].toUpperCase() : ""}
            </IconCard>
          )}
          <div>
            <span>User</span>
            <h3>{`${prop.firstName} ${prop.surname}`}</h3>
            <p>{prop.email}</p>
          </div>
        </UserProfileCard>
        <GridTexts>
          <p>Created</p>
          <h3>{moment(prop.createdAt).format("LL")}</h3>
        </GridTexts>
        <GridTexts>
          <p>Updated</p>
          <h3>{moment(prop.updatedAt).format("LL")}</h3>
        </GridTexts>
        <BalanceCard>
          <IconCard background="#4DC736" color="#fff">
            &#8358;
          </IconCard>
          <GridTexts>
            <p>Balance</p>
            <h3>&#8358;{CommaNumber(prop ? prop.walletBalance : "")}</h3>
          </GridTexts>
        </BalanceCard>
      </div>
    </UserCard>
  );
};

const lookupArray = [
  {
    name: "BVN",
  },
  {
    name: "Identity",
  },
];
