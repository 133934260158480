import React, { useState, useEffect } from 'react';
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, InputField, AccountGrid, AccountCard} from '../../styles/reusable/index';
import SideBarWidget from '../reusable/sidebar';
import Header from '../reusable/header';
import * as Icon from 'iconsax-react';
import * as FeatherIcon from 'react-feather';
import moment from 'moment';
import BreadcrumbArea from '../reusable/breadcrumb';
import BanksModal from './banksModal';
import { useNavigate } from 'react-router';

const Account = () => {
    const navigate = useNavigate();
    let [clickCount, setClickCount] = useState(0)
    const [bankState, setBankState] = useState('Activate')
    const [activeItem, setActiveItem] = useState(-1)
    const [showBankModal, setShowBankModal] = useState(false);

    useEffect(() => {
        if(clickCount > 0){
            setShowBankModal(true)
        }
    }, [clickCount, bankState]);
    
    return(
        <>
            {
                showBankModal ?
                    <BanksModal 
                        closeFunc={() => setShowBankModal(false)}
                        mode={bankState}
                    />
                : null
            }
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea 
                            navName='Account'
                            redirectUrl='/'
                        />
                        <DescHeader>
                            <h3>Account</h3>
                        </DescHeader>
                        <AccountGrid>
                            {
                                actionArray.map((item, index) => (
                                    <AccountCard
                                        key={index}
                                        onClick={() => {
                                            setActiveItem(index)
                                            if (item.name === "Bank Switch"){
                                                setShowBankModal(true);
                                            }
                                            navigate(item.route)
                                        }}
                                        bg={activeItem === index ? '#FFAB01' : ''}
                                        color={activeItem === index ? '#fff' : ''}
                                        border={activeItem === index ? 'none' : '1px solid #474747'}
                                    >
                                        {item.name}
                                    </AccountCard>
                                ))
                            }
                            
                        </AccountGrid>
                        {
                            activeItem === 0 ?
                                <div
                                    style={{
                                        margin: '4rem 0 0 0'
                                    }}
                                >
                                    <p>Bank Switch</p>
                                    <InputField
                                        style={{
                                            width: '15rem'
                                        }}
                                    >
                                        <legend>Select Action</legend>
                                        <select
                                            required
                                            value={bankState}
                                            placeholder={'Select Category'}
                                            onChange={(e) => {
                                                setBankState(e.target.value)
                                                setClickCount(++clickCount)
                                            }}
                                        >
                                            <option>Activate</option>
                                            <option>De-Activate</option>
                                        </select>
                                    </InputField>
                                </div>
                            : null 
                        }
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
    )
}

export default Account;

const actionArray = [
    
    {
        name: 'BVN/Identity Lookup',
        route: '/identity-lookup'
    },
    {
        name: 'Blockings',
        route: '/blockings'
    },
    {
        name: 'Bank Statement',
        route: ''
    },
    {
        name: 'Transfer Upgrade',
        route: '/transfer-upgrade'
    },
    {
        name: 'Account Information',
        route: '/reset-information'
    },
    {
        name: 'Update BVN',
        route: ''
    },
    {
        name: 'Fund Account',
        route: '/fund-account'
    },
    {
        name: 'Debit Account',
        route: '/debit-account'
    },
    {
        name: 'Disbursement Provider',
        route: '/providers'
    },
    {
        name: 'Update Access',
        route: ''
    },
    {
        name: 'Bank Switch',
        route: ''
    },
    {
        name: 'Business Model',
        route: '/business-model'
    },
]