import React, { useEffect, useState, useRef } from 'react';
import { DashboardMainFlex, DashboardMainBody, AppMainBody, MenuPopUp, DescHeader, FlexedBtn, DateWrap, MenuSpace, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, UserCard, IconCard,  HeaderItems, FilterSelect, IconBtn, UserProfileCard, StatusCard } from '../../styles/reusable/index';
import SideBarWidget from '../reusable/sidebar';
import Header from '../reusable/header';
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather';
// import { ChatAvatar } from '../../styles/chat';
import Typography from '../reusable/typography';
import { CardGridWrap, GridCard } from '../../styles/tickets';
import CreateTicket from '../dashboard/createTicket';
import moment from 'moment';
import Alert from '../reusable/alert';
import axios from 'axios';
import { useNavigate } from 'react-router';
import Loader from '../reusable/loader';
import EmptyState from '../reusable/emptyState';
import CsvDownloader from 'react-csv-downloader';
import BreadcrumbArea from '../reusable/breadcrumb';
import { handleBg, handleColor } from '../../utils/colorHandle';
import { ClipLoader } from 'react-spinners';
import PaginationComp from '../reusable/pagination';
import RangeModal from '../reusable/rangeModal';
import { AvatarImage } from '../reusable/style';

const TicketsMain = () => {

    const date = new Date();
    const navigate = useNavigate();
    const loggedAdmin = localStorage.getItem('tks') || '{}';
    const exportButton:any = useRef(null);

    // States
    const [activeItem, setActiveItem] = useState(-1);
    const [tickets, setTickets] = useState<any>('');
    const [closedTicket, setClosedTicket] = useState<any>([])
    const [ticketsMutable, setMutableTickets] = useState<any>([])
    const [openCreateTicket, setOpenCreateTicket] = useState(false);
    const [onProcess, setOnprocess] = useState(false);
    const [archiveProcess, setArchiveProcess] = useState(false)
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('')
    let [page, setPage] = useState(1)
    const [totalData, setTotalData] = useState<string | number | any>('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [openExport, setOpenExport] = useState(false);
    const [exportData, setExportData] = useState([])
    const [exportProcess, setExportProcess] = useState(false)

    const body = {
        headers: { 
            "Content-Type": "application/json",
            "x-token": `${loggedAdmin}`
        },
    }

    // Export Range
    const getRangeData = (e:any) => {
        e.preventDefault()
        if(startDate && endDate){
            setExportProcess(true)
            const loggedAdmin = localStorage.getItem('tks') || '{}';

            const body = {
                headers: { 
                    "Content-Type": "application/json",
                    "x-token": `${loggedAdmin}`
                },
            }

            axios.get(`${process.env.REACT_APP_BASE_URL}/range/ticket/${new Date(startDate).getTime()}/${new Date(endDate).getTime()}`, body)
                .then(res => {
                    setExportProcess(false)
                        if(res.data.statement.length > 0){
                            setExportData(res.data.statement)
                        }
                }).catch(err => {
                    setExportProcess(false)
                    if(err.response.status === 403){
                        localStorage.clear();
                        navigate('/login');
                    }
                })
            }
    }

    // Search Endpoint
    const searchItem = () => {
        if (searchedItem){
            axios.get(`${process.env.REACT_APP_BASE_URL}/admin/search/ticket?limit=20&page=${page}&key=${searchedItem}`, body)
            .then(res => {
                setTickets(res.data.data);
                setMutableTickets(res.data.data);
                setTotalData(res.data.data.length)
            }).catch(err => {
                if(err.response.status === 403){
                    localStorage.clear();
                    navigate('/login');
                }
            })
        }
    }

    useEffect(() => {
        if (exportData.length > 0){
            exportButton.current.click();
            setOpenExport(false)
        }
    }, [exportData])

    // Set States
    const [searchedItem, setSearchedItem] = useState<any>('')
    const [username, setUsername] = useState('')
    const [option, setOption] = useState('')
    const [amount, setAmount] = useState('')
    const [method, setMethod] = useState('')
    const [description, setDescription] = useState('')
    
    // Get Ticket Function
    const getTicket = () => {
        const body = {
            headers: { 
                "Content-Type": "application/json",
                "x-token": `${loggedAdmin}`
            },
        }
        axios.get(`${process.env.REACT_APP_BASE_URL}/admin/ticket/all?limit=20&page=${page}`, body)
            .then(res => {
                    setTickets(res.data.data.data);
                    setMutableTickets(res.data.data.data);
                    setTotalData(res.data.data.total)
            }).catch(err => {
                if(err.response.status === 403){
                    localStorage.clear();
                    navigate('/login');
                }
            })
    }
    // Get Tickets
    useEffect(() => {
        const loggedAdmin = localStorage.getItem('tks') || '{}';

        if(tickets.length === 0){
            getTicket();
        }
    }, []);

    const incrementAction = () => {
        if (page < Math.ceil(totalData / 20)){
            setPage(++page)
            getTicket();
        }
        
    }

    const decrementAction = () => {
        if (page > 1){
            setPage(--page)
            getTicket();
        }
    }
    

    useEffect(() => {
        let mockArray:any = []
        if (tickets && tickets.length > 0 && tickets){
            for (let i = 0; i < tickets.length; i++){
                if(tickets[i].status === 'Closed' || tickets[i].status === 'Closed Ticket'){
                mockArray.push(tickets[i])
                }
            }
        }
        setClosedTicket(mockArray)
    }, [tickets])

    // Refresh Filters
    const refreshFilter = () => {
        setSearchedItem('');
        getTicket();
    }

    // Search Algorithm
    const handleSearch: any = (e:any) => {
        let new_item: any = [];
        if (tickets !== '' && tickets.length !== 0) {
          for (let i = 0; i < tickets.length; i++) {
            if (
                tickets[i].ID && tickets[i].ID
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) ||

                tickets[i].username && tickets[i].username
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) ||

                tickets[i].method && tickets[i].method
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) ||

                tickets[i].status && tickets[i].status
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) ||

                moment(tickets[i].timeCreated).format('LL')
                .toLowerCase()
                .includes(e.target.value.toLowerCase())

            ) {
              new_item.push(tickets[i]);
            } else if (e.target.value === '') {
              setMutableTickets(tickets);
            }
          }
          setMutableTickets(new_item);
        }
      };

    // Create Ticket
    const createTicket = (e:any) => {
        e.preventDefault();
        const mockData:any = [...ticketsMutable];
        const basicCreatePayload = {
            username,
            option,
            method,
            amount,
            status: 'Open',
            description
        }
        setOnprocess(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}/admin/ticket/create`, basicCreatePayload, {
            headers: {
                "x-token": `${loggedAdmin}`
            }
        }).then((res) => {
            setSuccess(true);
            setOnprocess(false);
            setOpenCreateTicket(false);
            mockData.unshift(res.data.tickets);
        }).catch((err) => {
            setOnprocess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })

        setTickets(mockData)
        setMutableTickets(mockData)
    }

    const closeTicket = (tiid:string) => {
        setOnprocess(true)
        axios.put(`${process.env.REACT_APP_BASE_URL}/admin/ticket/close`, {tiid}, {
            headers: {
                "x-token": `${loggedAdmin}`
            }
        }).then((res) => {
            setOnprocess(false);
            setSuccess(true);
            setActiveItem(-1)
            getTicket();
        }).catch((err) => {
            setOnprocess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }

    const archiveTicket = (tiid:string) => {
        setArchiveProcess(true)
        axios.put(`${process.env.REACT_APP_BASE_URL}/admin/ticket/archive`, {tiid}, {
            headers: {
                "x-token": `${loggedAdmin}`
            }
        }).then((res) => {
            setArchiveProcess(false);
            setSuccess(true);
            setActiveItem(-1)
            getTicket();
        }).catch((err) => {
            setArchiveProcess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }

    // To Single Ticket
    const toSingle = (item: object) => {
        localStorage.setItem('ticket', JSON.stringify(item));
        navigate('/ticket-information');
    }

    // Other Arrays
    const cardsItem = [
        {
            name: 'Total Tickets',
            value: tickets.length,
            iconBg: '#F0CA43',
            iconColor: '',
            main: true,
            icon: Icon.Ticket2
        },
        {
            name: 'Open Tickets',
            value: tickets.length - closedTicket.length,
            iconBg: '#007EFF',
            iconColor: '#fff',
            main: false,
            icon: FeatherIcon.Check
        },
        {
            name: 'Resolved Tickets',
            value: closedTicket.length,
            iconBg: '#A2AD1F',
            iconColor: '#fff',
            main: false,
            icon: FeatherIcon.Clock
        },
        {
            name: 'Closed Tickets',
            value: closedTicket.length,
            iconBg: '#F04343',
            iconColor: '#fff',
            main: false,
            icon: FeatherIcon.X
        },
    ]

    return(
        <>
            {
                openExport ?
                    <RangeModal 
                        closeFunc={() => setOpenExport(false)}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        action={getRangeData}
                        onProcess={exportProcess}
                    />
                    : null
            }
            {
                success ? 
                    <Alert
                        closeFunc={() => setSuccess(false)}
                        img='/icons/ticket.png'
                        message='Ticket was modified'
                        miniMessage='You edited and modified this ticket successfully.'
                    />
                :null
            }
            {
                 error ? 
                    <Alert
                        closeFunc={() => setError('')}
                        img='/icons/error.png'
                        message='An error occured!'
                        miniMessage={error}
                    />
                    :null
            }
            {
                openCreateTicket ?

                    <CreateTicket 
                        closeFunc={() => setOpenCreateTicket(false)}
                        setUsername={setUsername}
                        setOption={setOption}
                        setAmount={setAmount}
                        setMethod={setMethod}
                        setDescription={setDescription}
                        onProcess={onProcess}
                        handleAction={createTicket}
                    />
                    : null
            }
            <DashboardMainFlex onClick={() => setActiveItem(-1)}>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea 
                            navName='Tickets'
                            redirectUrl='/'
                        />
                        <DescHeader>
                            <h3>Tickets ({totalData})</h3>
                        </DescHeader>
                        <CardGridWrap>
                            {
                                cardsItem.map((item, index) => (
                                    <GridCard
                                        key={index}
                                        background={item.main ? '#FFAB01' : ''}
                                        color={item.main ? '#fff' : ''}
                                    >
                                        <div>
                                            <Typography 
                                                text={item.name}
                                                fontSize='12px'
                                                fontWeight={400}
                                                color={item.main ? '#fff' : ''}
                                            />
                                            <Typography 
                                                text={item.value}
                                                fontSize='20px'
                                                fontWeight={700}
                                                color={item.main ? '#fff' : ''}
                                            />
                                        </div>
                                        <IconCard
                                            background={item.iconBg}
                                            color={item.iconColor}
                                        >
                                            {
                                                React.createElement(
                                                    item.icon
                                                )
                                            }
                                        </IconCard>
                                    </GridCard>
                                ))
                            }
                        </CardGridWrap>
                        <HeaderItems>
                            <DashboardInput 
                                wrapWidth={'40%'}
                                showSearch={true}
                                transform='none'
                            >
                                <input 
                                    placeholder='Search tickets here'
                                    value={searchedItem}
                                    onChange={(e) => {
                                        setSearchedItem(e.target.value)
                                    }}
                                />
                                <i>
                                    <Icon.SearchNormal1 
                                        size={18} 
                                        onClick={() => searchItem()}
                                    />
                                </i>
                            </DashboardInput>
                            <CsvDownloader
                                    datas={exportData}
                                    filename={`Tickets - ${moment(date)}`}
                                    
                                >
                                    <button ref={exportButton} style={{visibility: 'hidden'}}>
                                        
                                    </button>
                                </CsvDownloader>
                                <FlexedBtn
                                    onClick={() => setOpenExport(true)}
                                >
                                    <button>
                                        <Icon.DocumentDownload />
                                        Export
                                    </button>
                                </FlexedBtn>
                            
                            <IconBtn
                                background='#4DC736'
                                color='#fff'
                                onClick={() => refreshFilter()}
                            >
                                <Icon.ArrowRotateLeft color='#fff' />
                            </IconBtn>
                        </HeaderItems>
                        <TableWrap>
                            <TableFlex>
                                {/* <MenuSpace>
                                    <h3><input type='checkbox' /></h3>
                                </MenuSpace> */}
                                <SmallTableSpace>
                                    <h3>Ticket ID</h3>
                                </SmallTableSpace>
                                <DateWrap>
                                    <h3>Date</h3>
                                </DateWrap>
                                <TableName>
                                    <h3>User</h3>
                                </TableName>
                                <SmallTableSpace>
                                    <h3>Subject</h3>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <p>Status</p>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <h3>View</h3>
                                </SmallTableSpace>
                                <MenuSpace>
                                </MenuSpace>
                            </TableFlex>
                            <Line />
                            {
                                tickets !== '' ?
                                ticketsMutable && ticketsMutable.length > 0 ?
                                    <>
                                        {
                                            ticketsMutable.map((item:any, index:number) => (
                                                <TableFlex
                                                    key={index}
                                                    onClick={() => toSingle(item)}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    {/* <MenuSpace>
                                                        <input type='checkbox' />
                                                    </MenuSpace> */}
                                                    <SmallTableSpace>
                                                        <h3>{item.tiid}</h3>
                                                    </SmallTableSpace>
                                                    <DateWrap>
                                                        <p>{moment(item.timeCreated).format('LL')}</p>
                                                    </DateWrap>
                                                    <TableName>
                                                        <UserProfileCard>
                                                        {
                                                                    item.image ? 
                                                                        <AvatarImage 
                                                                            src={item.image}
                                                                            alt='Profile'
                                                                        />
                                                                        :
                                                                        <IconCard
                                                                            background='#FEF9E5'
                                                                            color='#FFAB01'
                                                                            style={{
                                                                                fontWeight: 800
                                                                            }}
                                                                        >
                                                                            {item.username && item.username[0].toUpperCase()}
                                                                        </IconCard>
                                                                }
                                                            <div>
                                                                <h3>{item.username}</h3>
                                                                <p>{item.email}</p>
                                                            </div>
                                                        </UserProfileCard>
                                                
                                                    </TableName>
                                                    <SmallTableSpace>
                                                        <p>{item.title}</p>
                                                    </SmallTableSpace>
                                                    <SmallTableSpace>
                                                        <StatusCard
                                                            bg={`${handleBg(item.status)}`}
                                                            color={`${handleColor(item.status)}`}
                                                        >
                                                            {item.status}
                                                        </StatusCard>
                                                    </SmallTableSpace>
                                                    <SmallTableSpace>
                                                        <p
                                                            style={{
                                                                color: '#ffab01'
                                                            }}
                                                        >View</p>
                                                    </SmallTableSpace>
                                                    <MenuSpace>
                                                        <FeatherIcon.MoreVertical 
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setActiveItem(index)
                                                            }}
                                                        />
                                                    </MenuSpace>
                                                    {
                                                        activeItem === index ?
                                                            <MenuPopUp>
                                                                <p
                                                                    style={{
                                                                        color:'#474747'
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        archiveTicket(item.tiid)
                                                                    }}
                                                                >{archiveProcess ? <ClipLoader color='#F2000B' size={20} /> : 'Archive Ticket'}</p>
                                                                <p
                                                                    style={{
                                                                        color:'#F2000B'
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        closeTicket(item.tiid)
                                                                    }}
                                                                >{onProcess ? <ClipLoader color='#F2000B' size={20} /> : 'Close Ticket'}</p>
                                                            </MenuPopUp>
                                                            : null
                                                    }
                                                </TableFlex>
                                            ))
                                        }
                                        <PaginationComp 
                                            page={page}
                                            setPage={setPage}
                                            limit={20}
                                            total={totalData}
                                            incrementAction={incrementAction}
                                            decrementAction={decrementAction}
                                        />
                                    </>
                                : 
                                    <EmptyState />
                                : 
                                <Loader />
                            }
                        </TableWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
    )
}

export default TicketsMain;