import React, { useState, useEffect } from 'react';
import { StatusCard, DashboardMainFlex, DashboardMainBody, BalanceCard, AppMainBody, DescHeader, MenuPopUp, TableWrap, TableFlex, SmallTableSpace, TableName, Line, Role, DateWrap, MenuSpace, UserCard, IconCard, BasicFlex, GreyText, UserProfileCard, GridTexts, ThreeSpaceGridWrap, SpaceWrap, HeroCover } from '../../styles/reusable/index';
import SideBarWidget from '../reusable/sidebar';
import Header from '../reusable/header';
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router';
import axios from 'axios';
import Loader from '../reusable/loader';
import { Link } from 'react-router-dom';
import CommaNumber from 'comma-number';
import EmptyState from '../reusable/emptyState';
import { AvatarImage } from '../reusable/style';
import BreadcrumbArea from '../reusable/breadcrumb';
import { handleBg, handleColor } from '../../utils/colorHandle';
import { ClipLoader } from 'react-spinners';
import { getKycStatus } from '../../utils/getKycStatus';

const UserLOGComp = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const loggedAdmin = localStorage.getItem('tks') || '{}';

    // States
    const [userInfo, setUserInfo] = useState<any>({});
    const [account, setAccount] = useState<any>({})
    const [bvn, setBvn] = useState('')

    // States
    const [tickets, setTickets] = useState<any>('');
    const [ticketsMutable, setMutableTickets] = useState<any>([])
    const [onProcess, setOnprocess] = useState(false);
    const [archiveProcess, setArchiveProcess] = useState(false)
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('')
    const [activeItem, setActiveItem] = useState(-1);

    // Get Tickets

    // Get Ticket Function
    const getTicket = () => {
        const loggedAdmin = localStorage.getItem('tks') || '{}';

        const body = {
            headers: { 
                "Content-Type": "application/json",
                "x-token": `${loggedAdmin}`
            },
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/admin/ticket/user/${id}`, body)
            .then(res => {
                setTickets(res.data);
                setMutableTickets(res.data);
            }).catch(err => {
                if(err.response.status === 403){
                    localStorage.clear();
                    navigate('/login');
                }
            })
    }
    useEffect(() => {
        getTicket();
    }, [])

    useEffect(() => {
        
        const loggedAdmin = localStorage.getItem('tks') || '{}';
        const body = {
            headers: { 
                "Content-Type": "application/json",
                "x-token": `${loggedAdmin}`
            },
        }
        
        if (id){
            axios.get(`${process.env.REACT_APP_BASE_URL}/admin/user/${id}`, body)
                .then(res => {
                    setUserInfo(res.data.data);
                    setAccount(res.data.data.Accounts[0])
                    setBvn(res.data.data.Bvn[0].data.idNumber)
                }).catch(err => {
                    if(err.response.status === 403){
                        localStorage.clear();
                        navigate('/login');
                    }
                })
        }
    }, [id])

    // To Single Ticket
    const toSingle = (item: object) => {
        localStorage.setItem('ticket', JSON.stringify(item));
        navigate('/ticket-information');
    }


    const closeTicket = (id:string) => {
        setOnprocess(true)
        axios.put(`${process.env.REACT_APP_BASE_URL}/admin/ticket/close`, {id}, {
            headers: {
                "x-token": `${loggedAdmin}`
            }
        }).then((res) => {
            setOnprocess(false);
            setSuccess(true);
            setActiveItem(-1)
            getTicket();
        }).catch((err) => {
            setOnprocess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }

    const archiveTicket = (id:string) => {
        setArchiveProcess(true)
        axios.put(`${process.env.REACT_APP_BASE_URL}/admin/ticket/archived`, {id}, {
            headers: {
                "x-token": `${loggedAdmin}`
            }
        }).then((res) => {
            setArchiveProcess(false);
            setSuccess(true);
            setActiveItem(-1)
            getTicket();
        }).catch((err) => {
            setArchiveProcess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }
    return(
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea 
                            navName='User'
                            redirectUrl='/users'
                        />
                        <DescHeader>
                            <h3>User</h3>
                        </DescHeader>
                        <TableWrap
                            style={{
                                padding: '0.5rem 15px 0 15px',
                                borderRadius: '30px 30px 0 0'
                            }}
                        >
                            <HeroCover></HeroCover>
                            <TableFlex>
                                <SmallTableSpace>
                                    <Link to={`/profile/${id}`}>
                                        <h3>Profile</h3>
                                    </Link>
                                </SmallTableSpace>
                                <DateWrap>
                                    <Link to={`/profile/account-information/${id}`}>
                                        <h3>Account Information</h3>
                                    </Link>
                                </DateWrap>
                                <DateWrap>
                                    <Link to={`/profile/transactions/${id}`}>
                                        <h3>Transactions</h3>
                                    </Link>
                                </DateWrap>
                                <SmallTableSpace>
                                    <Link to={`/profile/kyc/${id}`}>
                                        <h3>KYC</h3>
                                    </Link>
                                </SmallTableSpace>
                                <DateWrap>
                                    <h3
                                        style={{
                                            color: '#ffab01'
                                        }}
                                    >Complaint Log</h3>
                                </DateWrap>
                                <SmallTableSpace>
                                    <Link to={`/profile/audit-trail/${id}`}>
                                        <h3>Audit</h3>
                                    </Link>
                                </SmallTableSpace>
                            </TableFlex>
                        </TableWrap>
                        {
                            userInfo && Object.keys(userInfo).length > 0 ?
                            <>
                            <UserCard>
                                <div>
                                    <div>
                                        <p>User Id</p>
                                        <h3>{userInfo.uid}</h3>
                                    </div>
                                    <div>
                                        <BasicFlex>
                                            <IconCard background='#E0E3EB' color='#122466'>
                                                <Icon.Call />
                                            </IconCard>
                                            <div>
                                                <GreyText>PHONE</GreyText>
                                                <h3>{userInfo.phone}</h3>
                                            </div>
                                        </BasicFlex>
                                    </div>
                                    <GridTexts>
                                        <p>BVN</p>
                                        <h3>{bvn ? bvn : 'Not Verified'}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Account Number</p>
                                        <h3
                                            style={{
                                                fontSize: '13px'
                                            }}
                                        >{`${account ? account.accountNumber : ''} | ${account ? account.bankName : ''}`}</h3>
                                    </GridTexts>
                                </div>
                                <Line />
                                <div>
                                    <UserProfileCard>
                                    {
                                            userInfo.image ? 
                                                <AvatarImage 
                                                    src={userInfo.image}
                                                    alt='Profile'
                                                />
                                                :
                                                <IconCard
                                                    background='#FEF9E5'
                                                    color='#FFAB01'
                                                    style={{
                                                        fontWeight: 800
                                                    }}
                                                >
                                                    {userInfo.firstName ? userInfo.firstName[0].toUpperCase() : ''}
                                                </IconCard>
                                        }
                                        <div>
                                            <span>User</span>
                                            <h3>{`${userInfo.firstName} ${userInfo.surname}`}</h3>
                                            <p>{userInfo.email}</p>
                                        </div>
                                    </UserProfileCard>
                                    <GridTexts>
                                        <p>Created</p>
                                        <h3>{moment(userInfo.timeCreated).format('LL')}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Last Updated KYC</p>
                                        <h3>{userInfo.idDoc === 0 ? 'Not Updated' :   moment(userInfo.user_updated_at).format('LL')}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Status</p>
                                        <h3
                                            style={{
                                                color: '#ffab01'
                                            }}
                                        >{getKycStatus(userInfo.idDoc)}</h3>
                                    </GridTexts>
                                    <BalanceCard>
                                        <IconCard background='#4DC736' color='#fff'>
                                            &#8358;
                                        </IconCard>
                                        <GridTexts>
                                            <p>Balance</p>
                                            <h3>&#8358;{CommaNumber(userInfo ? userInfo.walletBalance : '')}</h3>
                                        </GridTexts>
                                    </BalanceCard>
                                </div>
                                <SpaceWrap>
                            
                                </SpaceWrap>
                            </UserCard>
                            <TableWrap>
                                <TableFlex>
                                    <MenuSpace>
                                        <h3><input type='checkbox' /></h3>
                                    </MenuSpace>
                                    <SmallTableSpace>
                                        <h3>Ticket ID</h3>
                                    </SmallTableSpace>
                                    <DateWrap>
                                        <h3>Date</h3>
                                    </DateWrap>
                                    <TableName>
                                        <h3>User</h3>
                                    </TableName>
                                    <SmallTableSpace>
                                        <h3>Subject</h3>
                                    </SmallTableSpace>
                                    <SmallTableSpace>
                                        <p>Status</p>
                                    </SmallTableSpace>
                                    <SmallTableSpace>
                                        <h3>View</h3>
                                    </SmallTableSpace>
                                    <MenuSpace>
                                    </MenuSpace>
                                </TableFlex>
                                <Line />
                                {
                                    tickets !== '' ?
                                    ticketsMutable && ticketsMutable.length > 0 ?
                                        <>
                                            {
                                                ticketsMutable.map((item:any, index:number) => (
                                                    <TableFlex
                                                        key={index}
                                                        onClick={() => toSingle(item)}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <MenuSpace>
                                                            <input type='checkbox' />
                                                        </MenuSpace>
                                                        <SmallTableSpace>
                                                            <h3>{item.ID}</h3>
                                                        </SmallTableSpace>
                                                        <DateWrap>
                                                            <p>{moment(item.time_created).format('LL')}</p>
                                                        </DateWrap>
                                                        <TableName>
                                                            <UserProfileCard>
                                                                {/* <ChatAvatar 
                                                                    src='/images/avatar.png'
                                                                    alt='User'
                                                                /> */}
                                                                <IconCard
                                                                    background='#FEF9E5'
                                                                    color='#FFAB01'
                                                                    style={{
                                                                        fontWeight: 800
                                                                    }}
                                                                >
                                                                    {item.username ? item.username[0] : ''}
                                                                </IconCard>
                                                                <div>
                                                                    <h3>{item.username}</h3>
                                                                </div>
                                                            </UserProfileCard>
                                                    
                                                        </TableName>
                                                        <SmallTableSpace>
                                                            <p>{item.method}</p>
                                                        </SmallTableSpace>
                                                        <SmallTableSpace>
                                                            <StatusCard
                                                                bg={`${handleBg(item.status)}`}
                                                                color={`${handleColor(item.status)}`}
                                                            >
                                                                {item.status}
                                                            </StatusCard>
                                                        </SmallTableSpace>
                                                        <SmallTableSpace>
                                                            <p
                                                                style={{
                                                                    color: '#ffab01'
                                                                }}
                                                            >View</p>
                                                        </SmallTableSpace>
                                                        <MenuSpace>
                                                            <FeatherIcon.MoreVertical 
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setActiveItem(index)
                                                                }}
                                                            />
                                                        </MenuSpace>
                                                        {
                                                            activeItem === index ?
                                                                <MenuPopUp>
                                                                    <p
                                                                        style={{
                                                                            color:'#474747'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            archiveTicket(item._id)
                                                                        }}
                                                                    >{archiveProcess ? <ClipLoader color='#F2000B' size={20} /> : 'Archive Ticket'}</p>
                                                                    <p
                                                                        style={{
                                                                            color:'#F2000B'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            closeTicket(item._id)
                                                                        }}
                                                                    >{onProcess ? <ClipLoader color='#F2000B' size={20} /> : 'Close Ticket'}</p>
                                                                </MenuPopUp>
                                                                : null
                                                        }
                                                    </TableFlex>
                                                ))
                                            }
                                        </>
                                        : 
                                        <EmptyState />
                                    : 
                                    <EmptyState />
                                }
                            </TableWrap>
                        </>
                            : 
                            <Loader />
                        }
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
    )
}

export default UserLOGComp;