import React from 'react';
import { ErrorWrap } from '../../styles/reusable';

interface PropsArgs {
    message: string;
}

const Error = ({message} : PropsArgs) => {
    return(
        <>
            <ErrorWrap>
                <p>
                    {message}
                </p>
            </ErrorWrap>
        </>
    )
}

export default Error;