import React, { useState, useEffect } from 'react';
import { DashboardMainFlex, DashboardMainBody, DateWrap, Role, MenuSpace, AppMainBody, DescHeader, FlexedBtn, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line } from '../../styles/reusable/index';
import SideBarWidget from '../reusable/sidebar';
import Header from '../reusable/header';
import * as Icon from 'iconsax-react';
import * as FeatherIcon from 'react-feather';
import InviteUser from './invite';
import axios from 'axios';
import Alert from '../reusable/alert';
import EmptyState from '../reusable/emptyState';
import Loader from '../reusable/loader';
import UsersList from './usersModal';
import CreateCategory from './createCategory';
import moment from 'moment';
import BreadcrumbArea from '../reusable/breadcrumb';

const Access = () => {
    
    const loggedAdmin:any = localStorage.getItem('tks') || '{}';
    const adminInformation:any = JSON.parse(localStorage.getItem('loggedAdmin') || '{}')
    const [email, setEmail] = useState('')
    const [openCreate, setOpenCreate] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [openInvite, setOpenInvite] = useState(false);
    const [onProcess, setOnprocess] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('')
    const [createSuccess, setCreateSuccess] = useState(false)

    // Generic States
    const [selectedForUserCheck, setSelectedForUserCheck] = useState('')
    const [service, setService] = useState('')
    const [selectedCat, setSelectedCat] = useState('Admin')
    const [category, setCategory] = useState<any>('');
    const [categoryMutable, setMutableCategory] = useState<any>([])

    // Get Categories

    const getCats = () => {
        const loggedAdmin = localStorage.getItem('tks') || '{}';

        const body = {
            headers: { 
                "Content-Type": "application/json",
                "x-token": `${loggedAdmin}`
            },
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/super/admin/category/all`, body)
            .then(res => {
                    setCategory(res.data.data);
                    setMutableCategory(res.data.data);
            }).catch(err => {})
    }
    useEffect(() => {
        getCats();
    }, [])

    // Invite Admin
    const InviteAdmin = (e:any) => {
        e.preventDefault();
        const basicCreatePayload = {
            email,
            category: selectedCat,
            superId: adminInformation._id
        }
        setOnprocess(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}/super/admin/category/invite`, basicCreatePayload, {
            headers: {
                "x-token": `${loggedAdmin}`
            }
        }).then((res) => {
            setSuccess(true);
            setOnprocess(false);
            setOpenInvite(false);
        }).catch((err) => {
            setOnprocess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }

    // Create Category
    const handleCreate = (e:any) => {
        e.preventDefault();
        const basicCreatePayload = {
            category: service
        }

        setOnprocess(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}/super/admin/category`, basicCreatePayload, {
            headers: {
                "x-token": `${loggedAdmin}`
            }
        }).then((res) => {
            setCreateSuccess(true);
            setOnprocess(false);
            setOpenCreate(false);
            getCats();
        }).catch((err) => {
            setOnprocess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }
    
    // Search Algorithm
    const handleSearch: any = (e:any) => {
        let new_item: any = [];
        if (category !== '' && category.length !== 0) {
          for (let i = 0; i < category.length; i++) {
            if (
                category[i].name && category[i].name
                .toLowerCase()
                .includes(e.target.value.toLowerCase())

            ) {
              new_item.push(category[i]);
            } else if (e.target.value === '') {
              setMutableCategory(category);
            }
          }
          setMutableCategory(new_item);
        }
      };
    return(
        <>
            {
                openModal ?
                    <UsersList 
                        closeFunc={() => setOpenModal(false)}
                        category={selectedForUserCheck}
                    />

                    : null
            }
            {
                success ? 
                    <Alert
                        closeFunc={() => setSuccess(false)}
                        img='/icons/success.png'
                        message='Invite sent successfully!'
                        miniMessage='You have successfully invited this email!'
                    />
                :null
            }
            {
                createSuccess ? 
                    <Alert
                        closeFunc={() => setCreateSuccess(false)}
                        img='/icons/success.png'
                        message='Category created successfully!'
                        miniMessage='You have successfully invited this category!'
                    />
                :null
            }
            {
                 error ? 
                    <Alert
                        closeFunc={() => setError('')}
                        img='/icons/error.png'
                        message='An error occured!'
                        miniMessage={error}
                    />
                    :null
            }
            {
                openCreate ?
                    <CreateCategory 
                        closeFunc={() => setOpenCreate(false)}
                        action={handleCreate}
                        name={service}
                        setName={setService}
                        onProcess={onProcess}
                    />
                    : null
            }
            {
                openInvite ?
                    <InviteUser 
                        closeFunc={() => setOpenInvite(false)}
                        email={email}
                        categoryList={categoryMutable}
                        setEmail={setEmail}
                        category={selectedCat}
                        setCategory={setSelectedCat}
                        action={InviteAdmin}
                        onProcess={onProcess}
                    />
                    : null
            }
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea 
                            navName='Manage Access/Admin'
                            redirectUrl='/'
                        />
                        <DescHeader>
                            <h3>Manage Access/Admin</h3>
                            <FlexedBtn>
                                <button
                                    onClick={(e) => setOpenCreate(true)}
                                >
                                    <Icon.TableDocument />
                                    Create Category
                                </button>
                                <button
                                    onClick={() => setOpenInvite(true)}
                                >
                                    <Icon.AddCircle />
                                    Add Admin
                                </button>
                            </FlexedBtn>
                        </DescHeader>
                        <DashboardInput
                            showSearch={true}
                            wrapWidth={'40%'}
                        >
                            <input 
                                placeholder='Search by user role here'
                                onChange={(e) => handleSearch(e)}
                            />
                            <i><Icon.SearchNormal1 size={18} /></i>
                        </DashboardInput>
                        <TableWrap>
                            <TableFlex>
                                <SmallTableSpace>
                                    <h3>#</h3>
                                </SmallTableSpace>
                                <Role>
                                    <h3>Role</h3>
                                </Role>
                                <DateWrap>
                                    <h3>Created</h3>
                                </DateWrap>
                                <DateWrap>
                                    <h3>Updated</h3>
                                </DateWrap>
                                {/* <SmallTableSpace>
                                    <h3>Number of users</h3>
                                </SmallTableSpace> */}
                                {/* <SmallTableSpace>
                                    <h3>Action</h3>
                                </SmallTableSpace> */}
                            </TableFlex>
                            <Line />
                            {
                                category !== '' ?
                                categoryMutable && categoryMutable.length > 0 ?
                                    <>
                                        {
                                            categoryMutable.map((item:any, index:number) => (
                                                <TableFlex
                                                    key={index}
                                                    onClick={() => {
                                                        setOpenModal(true);
                                                        setSelectedForUserCheck(item.category)
                                                    }}
                                                    useCursor={true}
                                                >
                                                    <SmallTableSpace>
                                                        <p>{index + 1}</p>
                                                    </SmallTableSpace>
                                                    <Role>
                                                        <p>{item.category}</p>
                                                    </Role>
                                                    <DateWrap>
                                                        <p>{moment(item.createdAt).format('LL')}</p>
                                                    </DateWrap>
                                                    <DateWrap>
                                                        <p>{moment(item.updatedAt).format('LL')}</p>
                                                    </DateWrap>
                                                    {/* <SmallTableSpace>
                                                        <p>0</p>
                                                    </SmallTableSpace> */}
                                                    {/* <SmallTableSpace>
                                                        <Icon.Edit2 
                                                            size={15}
                                                            color='#4DC736'
                                                            strokeWidth={1}
                                                        />
                                                    </SmallTableSpace> */}
                                                </TableFlex>
                                            ))
                                        }
                                    </>
                                : 
                                    <EmptyState />
                                : 
                                <Loader />
                            }
                        </TableWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
    )
}

export default Access;