import React, { useState, useEffect } from "react";
import { ModalWrap, ModalChild, ModalHeader, InputField, UnderlayText, UserProfileCard, IconCard } from "../../../styles/reusable/index";
import * as FeatherIcon from 'react-feather';
import { Button } from "../../../styles/reusable";
import { AvatarImage } from '../../reusable/style';
import Select from 'react-select';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { CustomStyles } from '../../../utils/selectStyle';
import { ClipLoader } from 'react-spinners';
import Alert from '../../reusable/alert';

interface PropArgs {
    closeFunc: any;
    action: any;
    getHeader: any;
    getIndex: any;
    type: string;
    onProcess: boolean;
}

const DiscountModal = ({
    closeFunc,
    action,
    getHeader,
    getIndex,
    type,
} : PropArgs) => {
    const width = window.innerWidth;
    const navigate = useNavigate();
    const [users, setUsers] = useState<any>('');
    const [usersMutable, setMutableUsers] = useState<any>([])
    const [selectedUser, setSelectedUser] = useState<any>({})
    const [fee, setFee] = useState('')
    const [cap, setCap] = useState('')
    const [onProcess, setOnProcess] = useState(false);
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)

    const loggedAdmin = localStorage.getItem('tks') || '{}';

    const body = {
        headers: { 
            "Content-Type": "application/json",
            "x-token": `${loggedAdmin}`
        },
    }

    // Call Function 
    const getUsers = () => {

        axios.get(`${process.env.REACT_APP_BASE_URL}/admin/users/all?paginate=false&limit=10000000`, body)
            .then(res => {
                setUsers(res.data.data.data);
                setMutableUsers(res.data.data.data);
            }).catch(err => {
                if(err.response.status === 403){
                    localStorage.clear();
                    navigate('/login');
                }
            })
    }

    const basicPayload = {
        businessName: 'Test',
        type: getIndex(),
        fee,
        cap,
        email: selectedUser.email,
        username: selectedUser.walletTag
    }

    const discountUser = (e:any) => {
        e.preventDefault();
        setOnProcess(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}/super/admin/business/discount/user`, basicPayload, {
            headers: {
                "x-token": `${loggedAdmin}`
            }
        }).then((res) => {
            setSuccess(true);
            setOnProcess(false);
        }).catch((err) => {
            setOnProcess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }

    // Get Users
    useEffect(() => {
        getUsers();
    }, [])

    return(
        <>
            <ModalWrap>
                <ModalChild
                    style={{
                        height: width > 728 ? '60vh' : '100%'
                    }}
                >
                    <ModalHeader>
                        <h3>{getHeader()}</h3>
                        <i><FeatherIcon.X onClick={() => closeFunc()} /></i>
                    </ModalHeader>
                    <form onSubmit={(e) => discountUser(e)}>
                        <UnderlayText>
                            Please, enter a new email address to Invite New Users
                        </UnderlayText>
                        <InputField
                            style={{
                                padding: '0',
                                border: 'none'
                            }}
                        >
                            <Select 
                                options={users}
                                styles={CustomStyles}
                                isSearchable
                                getOptionLabel={p => `${p.firstName} ${p.lastName}`}
                                onChange={(e:any) => setSelectedUser((e as HTMLSelectElement)) }
                                formatOptionLabel={(user:any) => (
                                    <UserProfileCard
                                        style={{
                                            padding: '0',
                                            margin: 0
                                        }}
                                    >
                                        {
                                            user.image ? 
                                                <AvatarImage 
                                                    src={user.image}
                                                    alt='Profile'
                                                    style={{
                                                        width: '2rem',
                                                        height: '2rem'
                                                    }}
                                                />
                                                :
                                                <IconCard
                                                    background='#FEF9E5'
                                                    color='#FFAB01'
                                                    style={{
                                                        fontWeight: 800,
                                                        width: '2rem',
                                                        height: '2rem'
                                                    }}
                                                >
                                                    {user.firstName && user.firstName[0].toUpperCase()}
                                                </IconCard>
                                        }
                                        <div>
                                            <h3>
                                                {`${user.firstName ? user.firstName : ''} 
                                                ${user.surname ? user.surname : ''}`}
                                                {
                                                    !user.firstName && !user.surname ? 
                                                    'No Name.': null
                                                }
                                            </h3>
                                        </div>
                                    </UserProfileCard>
                                )}
                            />
                        </InputField>
                        <InputField>
                            <legend>Fee (in %)</legend>
                            <input 
                                required
                                placeholder={'0'}
                                type='number'
                                onChange={(e) => setFee(e.target.value)}
                            />
                        </InputField>
                        <InputField>
                            <legend>Cap</legend>
                            <input 
                                placeholder={'0'}
                                type='number'
                                onChange={(e) => setCap(e.target.value)}
                            />
                        </InputField>
                        <Button
                            width='100%'
                            bg='var(--primary-color)'
                            color='#fff'
                            type='submit'
                            disabled={onProcess}
                            style={ onProcess ? {
                                cursor: 'not-allowed',
                            }: {}}
                        >
                            {onProcess ? <ClipLoader color='#fff' size={18} /> : 'Add User'}
                        </Button>
                    </form>
                </ModalChild>
            </ModalWrap>
            {
                success ? 
                    <Alert
                        closeFunc={() => {
                            setSuccess(false);
                            closeFunc();
                        }}
                        img='/icons/success.png'
                        message='Modified Successfully'
                        miniMessage="You have successfully added this user as a discounted user"
                    />
                :null
            }
            {
                 error ? 
                    <Alert
                        closeFunc={() => setError('')}
                        img='/icons/error.png'
                        message='An error occured!'
                        miniMessage={error}
                    />
                    :null
            }
        </>
    )
}

export default DiscountModal;
