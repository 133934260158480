import React, { useState, useEffect } from "react";
import { ModalWrap, ModalChild, ModalHeader, InputField, UnderlayText, UserProfileCard, IconCard } from "../../../styles/reusable/index";
import * as FeatherIcon from 'react-feather';
import { Button } from "../../../styles/reusable";
import { AvatarImage } from '../../reusable/style';
import Select from 'react-select';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { CustomStyles } from '../../../utils/selectStyle';
import { ClipLoader } from 'react-spinners';
import Alert from '../../reusable/alert';

interface PropArgs {
    closeFunc: any;
    active: string;
    setUser: any;
    setFetched: any;
    setBaseId: any;
}

const EnterIdModal = ({
    closeFunc,
    active,
    setUser,
    setFetched,
    setBaseId
} : PropArgs) => {
    const navigate = useNavigate();
    const [id, setId] = useState('')
    const [onProcess, setOnProcess] = useState(false);
    const [error, setError] = useState('')

    const loggedAdmin = localStorage.getItem('tks') || '{}';

    const body = {
        headers: { 
            "Content-Type": "application/json",
            "x-token": `${loggedAdmin}`
        },
    }

    const basicPayload = {
        check: `${active}`,
        idNumber: id
    }

    // Call Function 
    const validateID = (e:any) => {
        e.preventDefault();
        setOnProcess(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}/admin/panel/lookup`, basicPayload, body)
            .then(res => {
                setOnProcess(false)
                setFetched(true)
                setUser(res.data.data);
                closeFunc();
            }).catch(err => {
                setOnProcess(false);
                setError(err.response.data.message)
                if(err.response.status === 403){
                    localStorage.clear();
                    navigate('/login');
                }
            })
    }

    return(
        <>
            <ModalWrap>
                <ModalChild>
                    <ModalHeader>
                        <h3>{active === '1' ? 'BVN' : 'Identity'} Lookup</h3>
                        <i><FeatherIcon.X onClick={() => closeFunc()} /></i>
                    </ModalHeader>
                    <form onSubmit={(e) => validateID(e)}>
                        <UnderlayText>
                            Please enter {active === '1' ? 'BVN' : 'Id Number'}
                        </UnderlayText>
                        <InputField>
                            <legend>Enter {active === '1' ? 'BVN': 'Id number'}</legend>
                            <input 
                                placeholder={active === '1' ? 'BVN': 'Id number'}
                                type='text'
                                onChange={(e) => {
                                    setId(e.target.value);
                                    setBaseId(e.target.value)
                                }}
                                required
                            />
                        </InputField>
                        {
                            error ?
                                <p
                                    style={{
                                        color: '#c82b32',
                                        fontSize: '13px'
                                    }}
                                >
                                    {error}
                                </p>
                            : null
                        }
                        <Button
                            width='100%'
                            bg='#ADB7BE'
                            color='#fff'
                            type='submit'
                            disabled={onProcess}
                            style={ onProcess ? {
                                cursor: 'not-allowed',
                            }: {}}
                        >
                            {onProcess ? <ClipLoader color='#fff' /> : 'Check ID'}
                        </Button>
                    </form>
                </ModalChild>
            </ModalWrap>
        </>
    )
}

export default EnterIdModal;
