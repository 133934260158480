import React from 'react';
import { ChatHeader, ChatMainWrap, LineFlexCard, ChatAvatar, ChatHeadernav, MessageWrap, LeftMessage, RightMessage, ChatInputArea, ChatTextArea, ChatSendArea, SendButton } from '../../styles/chat';
import Typography from '../reusable/typography';
import * as Icon from 'iconsax-react';
import * as FeatherIcon from 'react-feather';
import { Line } from '../../styles/reusable/index';

const MainMessageDisplay = () => {
    return(
        <>
            <ChatMainWrap>
                <ChatHeader>
                    <LineFlexCard>
                        <div>
                            <ChatAvatar 
                                src={'images/avatar.png'}
                                alt='User'
                            />
                            <div>
                                <Typography 
                                    text={'Daniel Adewale'}
                                    fontSize='13px'
                                    color='#202020'
                                    fontWeight={700}
                                />
                                <Typography 
                                    text={'Ongoing'}
                                    fontSize='13px'
                                    color='#a5a5a5'
                                    fontWeight={400}
                                />
                            </div>
                        </div>
                        <ChatHeadernav>
                            <Icon.Star1 color='#FFAB2D' />
                            <FeatherIcon.MoreHorizontal color='#a5a5a5' />
                        </ChatHeadernav>
                    </LineFlexCard>
                </ChatHeader>
                <Line />
                <MessageWrap>
                    <LeftMessage>
                        <ChatAvatar 
                            src='images/avatar.png'
                            alt='User'
                        />
                        <div>
                            <Typography 
                                text={'Daniel Adewale'}
                                fontSize='13px'
                                color='#202020'
                                fontWeight={700}
                            />
                            <Typography 
                                text={'Lorem ipsume dolor sit amet, consectetur adipiscing elit. Dictum tortor ut nibh nisl ornare auctor augue id. Risus, risus elit consequat aliquet. Risus.'}
                                fontSize='13px'
                                color='#a5a5a5'
                                fontWeight={400}
                            />
                        </div>
                    </LeftMessage>
                    <RightMessage>
                        <div>
                            <Typography 
                                text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictum tortor ut nibh nisl ornare auctor.'}
                                fontSize='13px'
                                color='#fff'
                                fontWeight={400}
                            />
                        </div>
                    </RightMessage>
                </MessageWrap>
                <ChatInputArea>
                    <ChatTextArea></ChatTextArea>
                    <ChatSendArea>
                        <div>
                            <FeatherIcon.CornerUpLeft color='#a5a5a5' size={18} strokeWidth={3} />
                            <FeatherIcon.CornerUpRight color='#a5a5a5' size={18} strokeWidth={3} />
                            <FeatherIcon.Type color='#a5a5a5' size={18} strokeWidth={3} />
                            <FeatherIcon.Bold color='#a5a5a5' size={18} strokeWidth={3} />
                            <FeatherIcon.Underline color='#a5a5a5' size={18} strokeWidth={3} />
                            <FeatherIcon.Italic color='#a5a5a5' size={18} strokeWidth={3} />
                        </div>
                        <div>
                            <Icon.AttachCircle color='#FFAB01' />
                            <FeatherIcon.Smile color='#FFAB01' />
                            <SendButton>
                                <Icon.Send2 />
                                Send
                            </SendButton>
                        </div>
                    </ChatSendArea>
                </ChatInputArea>
            </ChatMainWrap>
        </>
    )
}

export default MainMessageDisplay;